import { api } from "../api/api";
import { getUserAuth } from "./getUserAuth";


export const getCustomerConnection = async (
  type: "mobile" | "vehicle" | "membership_code" | "certificate_number",
  value: string
) => {
  const token = getUserAuth();
  if (token) {
    const url =
      type === "mobile"
        ? `/check-vehicle-phone-exist?phone_number=${value}`
        : type === "vehicle"
        ? `/check-vehicle-phone-exist?vehicle_number=${value}`: type === "membership_code"
        ? `/check-vehicle-phone-exist?membership_code=${value}`:`/check-vehicle-phone-exist?certificate_number=${value}`;
    const res = await api.get(url, {
      headers: {
        "auth-Token": token,
      },
    });

    return res;
  } else {
    throw Error("token not found");
  }
};

export const getSowCustomerConnection = async (
  type: "mobile" | "vehicle" | "membership_code" | "certificate_number",
  value: string
) => {
  const token = getUserAuth();
  if (token) {
    const url =
      type === "mobile"
        ? `/sow-check-vehicle-phone-exist?phone_number=${value}`
        : type === "vehicle"
        ? `/sow-check-vehicle-phone-exist?vehicle_number=${value}`
        : `/sow-check-vehicle-phone-exist?membership_code=${value}`;
    const res = await api.get(url, {
      headers: {
        "auth-Token": token,
      },
    });

    return res;
  } else {
    throw Error("token not found");
  }
};


