import { Box, Button, MenuItem, SelectChangeEvent } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { api } from "../../../../api/api";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import {
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../components/formsComponents";
import { useAuth } from "../../../../contexts/AuthProvider/AuthProvider";
import { useMessagesPopup } from "../../../../contexts/MessagesPopupProvider/MessagesPopupProvider";
import { fetchRegisteredUserAsync } from "../../../../redux/registeredUsers/registeredUsersActions";
import { ICcCloseComplaintProps } from "./interfaces/ICcCloseComplaint";
import dayjs from "dayjs";

export const CcCloseComplaint: React.FC<ICcCloseComplaintProps> = (props) => {
  const { open, complaint, onClose, status } = props;

  const [loading, setLoading] = React.useState(false);
  const [isOtherRemark, setIsOtherRemark] = React.useState(false);

  const auth = useAuth();
  const { showTopSuccessSnackbar, showTopErrorSnackbar } = useMessagesPopup();
  const dispatch = useDispatch<any>();
  const [selectedSlot, setSelectedSlot] = React.useState<string | null>(null);
  const formatDateTime = (date: Date) => {
    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        quick_rsa_help_id: complaint.quick_rsa_help_id,
        mobile_no: complaint.mobile_no,
        vehicle_no: complaint.vehicle_no,
        status: "CLOSED",
        assign_to_shift: "",
        assign_to_shift_timing: "",
        category: complaint.category,
        remark: complaint.remark,
        remarkText: "",
        close_time: formatDateTime(new Date()),
      },
      validate: (values) => {
        const errors: any = {};
        if (values.mobile_no === "-1") {
          errors.mobile_no = "*You can't leave this empty";
        } else if (values.vehicle_no === "-1") {
          errors.vehicle_no = "*You can't leave this empty";
        }
        if (!values.assign_to_shift) {
          errors.assign_to_shift = "*Please select a shift";
        }
        if (!selectedSlot) {
          errors.assign_to_shift_timing = "*Please select a shift timing";
        }
        if (values.category === "-1") {
          errors.category = "*Please select a category";
        }
        if (!values.remark) {
          errors.remark = "*Please select a remark";
        }
        return errors;
      },
      onSubmit: async (values) => {
        try {
          setLoading(true);

          if (auth.user.tokens && auth.user.tokens.access) {
            await api.post(
              "/close-quick-rsa-help",
              {
                ...values,
                remark:
                  values.remark === "Others"
                    ? values.remarkText
                    : values.remark,
                assign_to_shift_timing: selectedSlot,
              },
              {
                headers: {
                  "auth-Token": auth.user.tokens.access,
                },
              }
            );

            dispatch(fetchRegisteredUserAsync());
            showTopSuccessSnackbar("Complaint Closed successfully!!");
            onClose();
          }
        } catch (err: any) {
          showTopErrorSnackbar(err.response.data.msg);
        } finally {
          setLoading(false);
        }
      },
    });

  const handleRemarkChange = (event: SelectChangeEvent<unknown>) => {
    console.log("step 1");

    const { value } = event.target;
    setFieldValue("remark", value);
    setIsOtherRemark(value === "Others");
  };

  const handleSlotChange = (slot: string) => {
    setSelectedSlot(slot);
  };

  return (
    <Dialog
      open={open}
      size="xs"
      title={status === "CLOSED" ? "Update RSA Request" : "Close RSA Request"}
      onClose={onClose}
      contentWrappedWithForm={{
        onSubmit: handleSubmit,
      }}
      actions={[
        {
          label: loading ? "Please wait..." : "Submit",
          type: "submit",
          disabled: loading,
          variant: "contained",
        },
      ]}
    >
      <CustomFormLabel>RSA Help ID</CustomFormLabel>
      <CustomTextField
        name="quick_rsa_help_id"
        fullWidth
        value={values.quick_rsa_help_id}
        onChange={handleChange}
        disabled
      />
      <CustomFormLabel>Status</CustomFormLabel>
      <CustomTextField
        name="status"
        fullWidth
        value={values.status}
        onChange={handleChange}
        disabled
      />
      <CustomFormLabel>Shift</CustomFormLabel>
      <CustomSelect
        id="assign_to_shift"
        name="assign_to_shift"
        value={values.assign_to_shift}
        fullWidth
        size="small"
        error={!!errors.assign_to_shift}
        onChange={handleChange}
      >
        <MenuItem key={"Day"} value={"Day"}>
          Day
        </MenuItem>
        <MenuItem key={"Night"} value={"Night"}>
          Night
        </MenuItem>
        <MenuItem key={"Day/Night"} value={"Day/Night"}>
          Day/Night
        </MenuItem>
      </CustomSelect>
      {errors.assign_to_shift && (
        <Box sx={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
          {errors.assign_to_shift}
        </Box>
      )}

      <CustomFormLabel>Shift Timing</CustomFormLabel>
      <Box display="flex" gap={1} flexWrap="wrap">
        {["9:30 AM - 7:00 PM", "7:00 PM - 9:30 AM"].map((slot) => (
          <Button
            key={slot}
            variant={selectedSlot === slot ? "contained" : "outlined"}
            onClick={() => handleSlotChange(slot)}
          >
            {slot}
          </Button>
        ))}
      </Box>
      {errors.assign_to_shift_timing && (
        <Box sx={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
          {errors.assign_to_shift_timing}
        </Box>
      )}
      <CustomFormLabel>Category</CustomFormLabel>
      <CustomSelect
        id="category"
        name="category"
        value={values.category}
        fullWidth
        size="small"
        error={errors.category ? true : false}
        onChange={handleChange}
      >
        <MenuItem key={"-1"} value={"-1"}>
          Choose Complaint Status
        </MenuItem>
        <MenuItem key={"CONVERTED"} value={"CONVERTED"}>
          Converted
        </MenuItem>
        <MenuItem key={"NOT_CONVERTED"} value={"NOT CONVERTED"}>
          Not Converted
        </MenuItem>
      </CustomSelect>

      <CustomFormLabel>Remark</CustomFormLabel>
      <CustomSelect
        id="remark"
        name="remark"
        value={values.remark}
        fullWidth
        size="small"
        error={errors.remark ? true : false}
        onChange={handleRemarkChange}
      >
        <MenuItem key={"Times Prime"} value={"Times Prime"}>
          Times Prime
        </MenuItem>
        <MenuItem key={"Short_Hangup"} value={"Short_Hangup"}>
          Short Hangup
        </MenuItem>
        <MenuItem key={"SOW"} value={"SOW"}>
          SOW
        </MenuItem>
        <MenuItem key={"Motor Insurance"} value={"Motor Insurance"}>
          Motor Insurance
        </MenuItem>
        <MenuItem key={"Amount High"} value={"Amount High"}>
          Amount High
        </MenuItem>
        <MenuItem key={"Generally Search"} value={"Generally Search"}>
          Generally_Search
        </MenuItem>
        <MenuItem key={"Car Sale"} value={"Car Sale"}>
          Car Sale
        </MenuItem>
        <MenuItem key={"Membership Sale"} value={"Membership Sale"}>
          Membership Sale
        </MenuItem>
        <MenuItem key={"Ring"} value={"Ring"}>
          Ring
        </MenuItem>
        <MenuItem key={"Call Back"} value={"Call Back"}>
          Call Back
        </MenuItem>
        <MenuItem key={"Follow Up"} value={"Follow Up"}>
          Follow Up
        </MenuItem>
        <MenuItem key={"Switch Off"} value={"Switch Off"}>
          Switch Off
        </MenuItem>
        <MenuItem key={"Not Interested"} value={"Not Interested"}>
          Not Interested
        </MenuItem>
        <MenuItem key={"Other Language"} value={"Other Language"}>
          Other Language
        </MenuItem>
        <MenuItem key={"RSA Enquiry"} value={"RSA Enquiry"}>
          RSA Enquiry
        </MenuItem>
        <MenuItem
          key={"Plan & Validity Confirm"}
          value={"Plan & Validity Confirm"}
        >
          Plan & Validity Confirm
        </MenuItem>
        <MenuItem key={"Services Required"} value={"Services Required"}>
          Services Required
        </MenuItem>
        <MenuItem key={"Local Help Taken"} value={"Local Help Taken"}>
          Local Help Taken
        </MenuItem>
        <MenuItem key={"Major Fault"} value={"Major Fault"}>
          Major Fault
        </MenuItem>
        <MenuItem key={"Already Member"} value={"Already Member"}>
          Already Member
        </MenuItem>
        <MenuItem
          key={"Not Satisfied With Crossroads"}
          value={"Not Satisfied With Crossroads"}
        >
          Not Satisfied With Crossroads
        </MenuItem>
        <MenuItem key={"Wrong Number"} value={"Wrong Number"}>
          Wrong Number
        </MenuItem>
        <MenuItem key={"Sales Team"} value={"Sales Team"}>
          Sales Team
        </MenuItem>
        <MenuItem key={"Others"} value={"Others"}>
          Others
        </MenuItem>
      </CustomSelect>
      {isOtherRemark && (
        <CustomTextField
          name="remarkText"
          fullWidth
          value={values.remarkText}
          onChange={handleChange}
          placeholder="Please specify"
          sx={{ marginTop: "20px" }}
        />
      )}
    </Dialog>
  );
};
