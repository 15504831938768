import produce from "immer";
import { CallCampaignActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  FETCH_CALL_CAMPAIGN_LIST_PROGRESS,
  FETCH_CALL_CAMPAIGN_LIST_SUCCESS,
  FETCH_CALL_CAMPAIGN_LIST_FAILED,
  FETCH_RECORD_COUNTS_FAILED,
  FETCH_RECORD_COUNTS_PROGRESS,
  FETCH_RECORD_COUNTS_SUCCESS,
  FETCH_TOTAL_RECORDS_FAILED,
  FETCH_TOTAL_RECORDS_PROGRESS,
  FETCH_TOTAL_RECORDS_SUCCESS,
} from "./CallCampaignAction";
import { defaultCallCampaignState } from "./defaultState";

export const callCampaignReducer = (
  state: IStoreState["callCampaign"] = defaultCallCampaignState,
  action: CallCampaignActions
) => {
  switch (action.type) {
    case FETCH_CALL_CAMPAIGN_LIST_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CALL_CAMPAIGN_LIST_SUCCESS: {
      const { list } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
      });
      return newState;
    }
    case FETCH_CALL_CAMPAIGN_LIST_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.list.loading = LoadState.Failed;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_TOTAL_RECORDS_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.totalRecords.data = "";
      });
      return newState;
    }
    case FETCH_TOTAL_RECORDS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.totalRecords.data = data;
      });
      return newState;
    }
    case FETCH_TOTAL_RECORDS_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.totalRecords.data = "";
      });
      return newState;
    }
    case FETCH_RECORD_COUNTS_PROGRESS: {
      const newState = produce(state, (draftState: any) => {
        draftState.recordCount.loading = LoadState.InProgress;
        draftState.recordCount.data = [];
      });
      return newState;
    }
    case FETCH_RECORD_COUNTS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState: any) => {
        draftState.recordCount.loading = LoadState.Loaded;
        draftState.recordCount.data = data;
      });
      return newState;
    }
    case FETCH_RECORD_COUNTS_FAILED: {
      const newState = produce(state, (draftState: any) => {
        draftState.recordCount.loading = LoadState.Failed;
        draftState.recordCount.data = [];
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
